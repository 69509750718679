import connectors from '@/helpers/connectors.json';
import networks from '@/helpers/networks.json';

const config = {
    env: 'master',
    connectors,
    networks,
    defaultNetwork: 56
};

const domainName = window.location.hostname;
if (domainName.includes('localhost')) config.env = 'local';
if (domainName === 'dev.vote.gyro.money') config.env = 'develop';

export default config;
