<template>
    <Container :slim="true">
        <div class="px-4 px-md-0 mb-3">
            <router-link :to="{name: domain ? 'home' : 'proposals'}" class="text-gray">
                <Icon name="back" size="22" class="v-align-middle" />
                {{ space.name }}
            </router-link>
        </div>
        <div>
            <div class="col-12 col-lg-8 float-left pr-0 pr-lg-5">
                <div class="px-4 px-md-0">
                    <template v-if="loaded">
                        <h1 class="mb-2">
                            {{ title }}
                            <span v-text="`#${id.slice(0, 7)}`" class="text-gray" />
                        </h1>
                        <State :proposal="proposal" class="mb-4" />
                        <div class="mb-2">
                            <p>{{ $t('page.transaction') }}</p>
                            <a
                                v-text="transactionHash"
                                :href="explorer + '/tx/' + transactionHash"
                                target="_blank"
                            />
                        </div>
                        <div class="mb-4">
                            <p>{{ $t('page.proposalParams') }}</p>
                            <div v-for="(action, i) in actions" :key="i" class="mb-1">
                                {{ i + 1 }}：
                                <a
                                    v-text="action.contractName"
                                    :href="explorer + '/address/' + action.contractAddress"
                                    target="_blank"
                                />
                                .
                                {{ action.funcName }}
                                <div>
                                    {{ action.data }}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <UiMarkdown :body="body" class="mb-6" />
                    </template>
                    <PageLoading v-else />
                </div>
                <Block
                    v-if="loaded && payload.state === 'Active' && !receipt.hasVoted"
                    class="mb-4"
                    :title="$t('page.voteTitle')"
                >
                    <div class="select-button">
                        {{ $t('page.tokenIds') }}
                        <UiButton class="button" @click="targetVisible = !targetVisible">
                            {{ selectId }}
                        </UiButton>
                        <div v-if="targetVisible" class="select">
                            <div
                                :class="['select-item', tokenId === selectId ? 'active' : '']"
                                @click="loadPower(tokenId)"
                                v-for="(tokenId, index) in tokenIds"
                                :key="index"
                            >
                                {{ tokenId }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <UiButton
                            v-for="(choice, i) in payload.choices"
                            :key="i"
                            @click="selectedChoice = i + 1"
                            class="d-block width-full mb-2"
                            :class="selectedChoice === i + 1 && 'button--active'"
                        >
                            {{ choice }}
                        </UiButton>
                    </div>
                    <UiButton
                        :disabled="voteLoading || !selectedChoice || !web3.account"
                        :loading="voteLoading"
                        @click="modalOpen = true"
                        class="d-block width-full button--submit"
                    >
                        {{ $t('page.vote') }}
                    </UiButton>
                </Block>
                <BlockVotes v-if="loaded" :space="space" :proposal="proposal" :votes="votes" />
            </div>
            <div v-if="loaded" class="col-12 col-lg-4 float-left">
                <Block :title="$t('page.information')">
                    <div class="mb-1">
                        <b>{{ $t('page.author') }}</b>
                        <User :address="proposal.address" :space="space" class="float-right" />
                    </div>
                    <div class="mb-1">
                        <b>{{ $t('page.id') }}</b>
                        <a class="float-right">#{{ proposal.id }}</a>
                    </div>
                    <div>
                        <div class="mb-1">
                            <b>{{ $t('page.startDate') }}</b>
                            <span
                                :aria-label="_ms(payload.start)"
                                v-text="$d(payload.start * 1e3, 'short')"
                                class="float-right text-white tooltipped tooltipped-n"
                            />
                        </div>
                        <div class="mb-1">
                            <b>{{ $t('page.endDate') }}</b>
                            <span
                                :aria-label="_ms(payload.end)"
                                v-text="$d(payload.end * 1e3, 'short')"
                                class="float-right text-white tooltipped tooltipped-n"
                            />
                        </div>
                    </div>
                </Block>
                <BlockResults
                    :id="id"
                    :space="space"
                    :payload="payload"
                    :results="results"
                    :votes="votes"
                />
            </div>
        </div>
        <ModalConfirm
            v-if="loaded"
            :open="modalOpen"
            @close="modalOpen = false"
            @reload="loadProposal"
            :space="space"
            :proposal="proposal"
            :id="id"
            :tokenId="selectId"
            :selectedChoice="selectedChoice"
            :totalScore="totalScore"
            :scores="scores"
            :snapshot="payload.snapshot"
        />
    </Container>
</template>

<script>
import {mapActions} from 'vuex';
import networks from '@/helpers/networks.json';
import {TARGETS} from '@/helpers/proposal';
import {lsGet, lsSet} from '@/helpers/utils';

export default {
    data() {
        return {
            key: 'gyro',
            id: this.$route.params.id,
            loading: false,
            loaded: false,
            voteLoading: false,
            proposal: {},
            votes: {},
            results: [],
            receipt: {},
            hasVoted: false,
            modalOpen: false,
            modalStrategiesOpen: false,
            selectedChoice: 0,
            totalScore: 0,
            targetVisible: false,
            scores: [],
            tokenIds: [],
            selectId: null
        };
    },
    computed: {
        networkId() {
            return this.web3.network.chainId;
        },
        explorer() {
            return networks[this.web3.network.chainId].explorer;
        },
        space() {
            const space = this.app.spaces[this.web3.network.chainId];
            return space || {};
        },
        payload() {
            return this.proposal && this.proposal.msg ? this.proposal.msg.payload : {};
        },
        title() {
            return this.proposal && this.proposal.msg
                ? this.proposal.msg.payload.name.split(';')[0]
                : '';
        },
        body() {
            if (!this.proposal || !this.proposal.msg) return '';
            const context = this.proposal.msg.payload.name;
            if (context) {
                const title = context.split(';')[0];
                return context.slice(title.length + 1, context.length);
            } else {
                return '';
            }
        },
        transactionHash() {
            if (!this.proposal || !this.proposal.msg || !this.proposal.msg.payload) return '';
            return this.proposal.msg.payload.transactionHash;
        },
        ts() {
            return (Date.now() / 1e3).toFixed();
        },
        symbols() {
            return this.space.strategies.map(strategy => strategy.params.symbol);
        }
    },
    watch: {
        'web3.account': async function(val, prev) {
            if (val && prev && val.toLowerCase() !== prev) {
                this.loading = true;
                this.loaded = false;
                await this.loadTokenIds();
                await this.loadProposal();
                this.loading = false;
                this.loaded = true;
            }
        },
        'web3.network.chainId': async function(val, prev) {
            if (val && prev && val.toString() !== prev.toString()) {
                this.loading = true;
                this.loaded = false;
                await this.loadTokenIds();
                await this.loadProposal();
                this.loading = false;
                this.loaded = true;
            }
        }
    },
    methods: {
        ...mapActions([
            'getProposal',
            'getProposalActions',
            'getPower',
            'getTokenIds',
            'encode',
            'decode',
            'send',
            'getReceipt'
        ]),
        lsGet,
        lsSet,
        async loadProposal() {
            const proposalObj = await this.getProposal({
                space: this.space,
                id: this.id
            });
            this.receipt = await this.getReceipt({
                space: this.space,
                id: this.id
            });
            const actions = await this.getProposalActions({
                space: this.space,
                id: this.id
            });
            const decode = this.decode;
            this.actions = [];
            for (let i = 0; i < actions[0].length; i++) {
                const v = actions[0][i];
                let contractName;
                const target = TARGETS[this.networkId];
                for (const key in target) {
                    if (v.toLowerCase() === target[key]?.address?.toLowerCase()) {
                        contractName = key;
                    }
                }
                const funcName = actions[2][i];
                const types = funcName
                    .split('(')[1]
                    .split(')')[0]
                    .split(',');
                const data = await decode({
                    types: types,
                    values: actions[3][i]
                });

                this.actions.push({
                    contractName,
                    contractAddress: v,
                    funcName: funcName.split('(')[0] + '(' + data.join(',') + ')',
                    data
                });
            }
            if (proposalObj) {
                this.proposal = proposalObj.proposal;
                this.votes = proposalObj.votes;
                this.results = proposalObj.results;
                this.hasVoted = proposalObj.hasVoted;
            } else {
                this.proposal = {};
                this.votes = {};
                this.results = [];
                this.hasVoted = false;
            }
        },
        async loadTokenIds() {
            if (!this.web3.account) return;
            const ids = await this.getTokenIds({
                space: this.space,
                account: this.web3.account
            });
            this.tokenIds = ids || [];
            if (!this.selectId && ids.length > 0) await this.loadPower(ids[0]);
        },
        async loadPower(tokenId) {
            if (!this.web3.account) return;
            this.selectId = tokenId;
            const {scores, totalScore} = await this.getPower({
                space: this.space,
                tokenId,
                blockNumber: this.payload.startBlock
            });
            this.totalScore = totalScore || 0;
            this.scores = scores || [];
            this.targetVisible = false;
        }
    },
    async created() {
        this.loading = true;
        await this.loadTokenIds();
        await this.loadProposal();
        this.loading = false;
        this.loaded = true;
    }
};
</script>

<style lang="scss">
.select-button {
    position: relative;
    .button {
        border-radius: 3px !important;
        width: 100%;
    }
    .select {
        z-index: 9999;
        transition: all 150ms 0ms;
        min-width: 80px;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        position: absolute;
        background-color: #fff;
        border-radius: 8px;
        padding: 10px 15px;
        top: 54px;
        right: 0px;
        .select-item {
            line-height: 30px;
            cursor: pointer;
            &:hover,
            &.active {
                color: #000;
            }
        }
    }
}
</style>
