export const TARGETS = {
    '56': {
        voter: {
            address: '0x72F782Beb4A859e2B2a23227Be1F9f2275171aF9',
            actions: [
                {
                    signature: 'createGauge(address)',
                    params: ['bond address'],
                    types: ['address']
                }
            ]
        }
        // distributor: {
        //     address: '0x784092641AE448a03Fe9C43CEe6A9337713ddbef',
        //     actions: [
        //         {
        //             signature: 'addRecipient(address,uint256)',
        //             params: ['recipient address', 'reward rate'],
        //             types: ['address', 'uint256']
        //         },
        //         {
        //             signature: 'removeRecipient(uint256,address)',
        //             params: ['index', 'target address'],
        //             types: ['uint256', 'address']
        //         },
        //         {
        //             signature: 'setAdjustment(uint256,bool,uint256,uint256)',
        //             params: ['index', 'add', 'reward rate', 'target address'],
        //             types: ['uint256', 'bool', 'uint256', 'uint256']
        //         }
        //     ]
        // },
        // gyro: {
        //     address: '0x1B239ABe619e74232c827FBE5E49a4C072bD869D',
        //     actions: [
        //         {
        //             signature: 'setNonCirculatingAddresses(address[])',
        //             params: ['non circulating address'],
        //             types: ['address[]']
        //         }
        //     ]
        // },
        // gyroBond: {
        //     address: '0xd6b1997149f1114f6251B6df1D907770Ba6df819',
        //     actions: [
        //         {
        //             signature: 'setBondTerms(uint256,uint256)',
        //             params: ['PARAMETER', 'input'],
        //             types: ['uint256', 'uint256']
        //         },
        //         {
        //             signature: 'setAdjustment(bool,uint256,uint256,uint256)',
        //             params: ['add', 'rate', 'target', 'buffer'],
        //             types: ['bool', 'uint256', 'uint256', 'uint256']
        //         },
        //         {
        //             signature: 'setVault(address)',
        //             params: ['vault'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setReferral(address)',
        //             params: ['referral'],
        //             types: ['address']
        //         }
        //     ]
        // },
        // gyroBondHelper: {
        //     address: '0xf74E8cFd7A38e4069Cd63DaC1012e190991652AE',
        //     actions: [
        //         {
        //             signature: 'recoverLostToken(address)',
        //             params: ['token'],
        //             types: ['address']
        //         }
        //     ]
        // },
        // gyroBondPCSHelper: {
        //     address: '0x170Da1A957e816aA030dE37b71Ba0e9746afC5eF',
        //     actions: [
        //         {
        //             signature: 'recoverLostToken(address)',
        //             params: ['token'],
        //             types: ['address']
        //         }
        //     ]
        // },
        // gyroVault: {
        //     address: '0x96a8FC2D80F464Bd734Ce8ED31Bd34a48176e5a7',
        //     actions: [
        //         {
        //             signature: 'setContract(uint256,address)',
        //             params: ['contract', 'address'],
        //             types: ['uint256', 'address']
        //         },
        //         {
        //             signature: 'setEscrowPeriod(uint256)',
        //             params: ['escrowPeriod'],
        //             types: ['uint256']
        //         }
        //     ]
        // },
        // referral: {
        //     address: '0x9116cb9930e0f7C5bc35e33f17F6b892Af6E763a',
        //     actions: [
        //         {
        //             signature: 'setRewardToken(address)',
        //             params: ['rewardToken'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setTreasury(address)',
        //             params: ['treasury'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setFee(uint256)',
        //             params: ['fee'],
        //             types: ['uint256']
        //         },
        //         {
        //             signature: 'setRewardShares(uint256,uint256)',
        //             params: ['referrerShare', 'depositorShare'],
        //             types: ['uint256', 'uint256']
        //         }
        //     ]
        // },
        // reservoir: {
        //     address: '0x8B1522402FECe066d83E0F6C97024248Be3C8c01',
        //     actions: [
        //         {
        //             signature: 'auditAssets()',
        //             params: [''],
        //             types: ['']
        //         },
        //         {
        //             signature: 'queue(uint256,address)',
        //             params: ['target', 'address'],
        //             types: ['uint256', 'address']
        //         },
        //         {
        //             signature: 'toggle(uint256,address)',
        //             params: ['target', 'address'],
        //             types: ['uint256', 'address']
        //         }
        //     ]
        // }
    },
    '97': {
        voter: {
            address: '0x71125D4Bc1320e4228D190E03E25c35351d58fF1',
            actions: [
                {
                    signature: 'createGauge(address)',
                    params: ['bond address'],
                    types: ['address']
                }
            ]
        }
        // gyro: {
        //     address: '0xc67bD01d0B9585b6A5250F0cf7De42566c3186D9',
        //     actions: [
        //         {
        //             signature: 'setNonCirculatingAddresses(address[])',
        //             params: ['non circulating address'],
        //             types: ['address[]']
        //         }
        //     ]
        // },
        // usdtBond: {
        //     address: '0xd1F24ff48AF900915c89c29615D0ba4A88BCaBdE',
        //     actions: [
        //         {
        //             signature: 'setBondTerms(uint256,uint256)',
        //             params: ['PARAMETER', 'input'],
        //             types: ['uint256', 'uint256']
        //         },
        //         {
        //             signature: 'setAdjustment(bool,uint256,uint256,uint256)',
        //             params: ['add', 'rate', 'target', 'buffer'],
        //             types: ['bool', 'uint256', 'uint256', 'uint256']
        //         },
        //         {
        //             signature: 'setVault(address)',
        //             params: ['vault'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setReferral(address)',
        //             params: ['referral'],
        //             types: ['address']
        //         }
        //     ]
        // },
        // usdtLpBond: {
        //     address: '0x11253Bb47106537d236689B18DA80b6A34455228',
        //     actions: [
        //         {
        //             signature: 'setBondTerms(uint256,uint256)',
        //             params: ['PARAMETER', 'input'],
        //             types: ['uint256', 'uint256']
        //         },
        //         {
        //             signature: 'setAdjustment(bool,uint256,uint256,uint256)',
        //             params: ['add', 'rate', 'target', 'buffer'],
        //             types: ['bool', 'uint256', 'uint256', 'uint256']
        //         },
        //         {
        //             signature: 'setVault(address)',
        //             params: ['vault'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setReferral(address)',
        //             params: ['referral'],
        //             types: ['address']
        //         }
        //     ]
        // },
        // gyroBondPCSHelper: {
        //     address: '0x4208a70C07717F6dCa090B041eA14b848418657f',
        //     actions: [
        //         {
        //             signature: 'recoverLostToken(address)',
        //             params: ['token'],
        //             types: ['address']
        //         }
        //     ]
        // },
        // referral: {
        //     address: '0x2B72690683Ca1400C0ac569414eeE7E4C71F4bfe',
        //     actions: [
        //         {
        //             signature: 'setRewardToken(address)',
        //             params: ['rewardToken'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setTreasury(address)',
        //             params: ['treasury'],
        //             types: ['address']
        //         },
        //         {
        //             signature: 'setFee(uint256)',
        //             params: ['fee'],
        //             types: ['uint256']
        //         },
        //         {
        //             signature: 'setRewardShares(uint256,uint256)',
        //             params: ['referrerShare', 'depositorShare'],
        //             types: ['uint256', 'uint256']
        //         }
        //     ]
        // },
        // reservoir: {
        //     address: '0xB8260AFf40321Afe86B5618D70cB34D661B9b3b1',
        //     actions: [
        //         {
        //             signature: 'auditAssets()',
        //             params: [''],
        //             types: ['']
        //         },
        //         {
        //             signature: 'queue(uint256,address)',
        //             params: ['target', 'address'],
        //             types: ['uint256', 'address']
        //         },
        //         {
        //             signature: 'toggle(uint256,address)',
        //             params: ['target', 'address'],
        //             types: ['uint256', 'address']
        //         }
        //     ]
        // }
    }
};
