import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Proposals from '@/views/Proposals.vue';
import Proposal from '@/views/Proposal.vue';
import Create from '@/views/Create.vue';
import Settings from '@/views/Settings.vue';
import Networks from '@/views/Networks.vue';

Vue.use(VueRouter);
const domainName = window.location.hostname;

const routes: Array<RouteConfig> = [
    {path: '/gyro/proposal/:id', name: 'proposal', component: Proposal},
    {path: '/gyro/create', name: 'create', component: Create},
    {path: '/gyro', name: 'proposals', component: Proposals},
    {path: '/gyro/:tab', name: 'proposals-tab', component: Proposals},
    {
        path: '/',
        redirect: '/gyro' // Set default route
    },
    {path: '/*', name: 'error-404', beforeEnter: (to, from, next) => next('/')}
];

const router = new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

export default router;
