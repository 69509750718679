<template>
    <UiModal :open="open" @close="$emit('close')">
        <h3 class="m-4 text-center">{{ $t('page.about') }}</h3>
        <div class="text-center">
            <a href="https://twitter.com/gyrodao" target="_blank">
                <Icon size="32" name="twitter" class="mr-1 mx-2" />
            </a>
            //
            <a href="https://discord.gg/gyrodao" target="_blank">
                //
                <Icon size="32" name="discord" class="mr-1 mx-2" />
                //
            </a>
            <a href="https://t.me/gyrodao" target="_blank">
                <Icon size="32" name="telegram" class="mr-1 mx-2" />
            </a>
            <a :href="`${pkg.repository}`" target="_blank">
                <Icon size="32" name="github" class="mr-1 mx-2" />
            </a>
        </div>
        <div class="m-4 p-4 border rounded-2 text-white">
            <div class="d-flex">
                <span v-text="'Version'" class="flex-auto text-gray mr-1" />
                {{ pkg.version }}
            </div>
            <div class="d-flex">
                <span v-text="'License'" class="flex-auto text-gray mr-1" />
                {{ pkg.license }}
            </div>
            <div class="d-flex">
                <span v-text="'Network'" class="flex-auto text-gray mr-1" />
                {{ web3.network.network }} ({{ web3.network.chainId }})
            </div>
            <!-- <div class="d-flex">
        <span v-text="'IPFS server'" class="flex-auto text-gray mr-1" />
        {{ ipfsNode }}
      </div>
      <div class="d-flex">
        <span v-text="'Hub'" class="flex-auto text-gray mr-1" />
        {{ hubUrl }}
      </div> -->
        </div>
    </UiModal>
</template>

<script>
import pkg from '@/../package.json';

export default {
    props: ['open'],
    data() {
        return {
            pkg,
            hubUrl: process.env.VUE_APP_HUB_URL,
            ipfsNode: process.env.VUE_APP_IPFS_NODE
        };
    }
};
</script>
