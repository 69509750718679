var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Block',{attrs:{"title":_vm.ts >= _vm.payload.end ? _vm.$t('page.results') : _vm.$t('page.currentResults')}},[_vm._l((_vm.payload.choices),function(choice,i){return _c('div',{key:i},[_c('div',{staticClass:"text-white mb-1"},[_c('span',{staticClass:"mr-1",domProps:{"textContent":_vm._s(_vm._shorten(choice, 'choice'))}}),_c('span',{staticClass:"mr-1 "},[_vm._v(" "+_vm._s(_vm._numeral(_vm.results.totalBalances[i]))+" "+_vm._s(_vm._shorten(_vm.space.symbol, 'symbol'))+" ")]),_c('span',{staticClass:"float-right",domProps:{"textContent":_vm._s(
                    _vm.$n(
                        !_vm.results.totalVotesBalances
                            ? 0
                            : ((100 / _vm.results.totalVotesBalances) * _vm.results.totalBalances[i]) /
                                  1e2,
                        'percent'
                    )
                )}})]),_c('UiProgress',{staticClass:"mb-3",attrs:{"value":_vm.results.totalScores[i],"max":_vm.results.totalVotesBalances,"titles":_vm.titles}})],1)}),(_vm.ts >= _vm.payload.end)?_c('div',[(_vm.payload.state === 'Succeeded')?_c('UiButton',{staticClass:"d-block width-full button--submit",on:{"click":_vm.queue}},[_vm._v(" "+_vm._s(_vm.$t('page.queue'))+" ")]):_vm._e(),(_vm.payload.state === 'Queued')?_c('div',{staticClass:"mb-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('page.eta')))]),_c('span',{staticClass:"float-right text-white tooltipped tooltipped-n",attrs:{"aria-label":_vm._ms(_vm.payload.eta)},domProps:{"textContent":_vm._s(_vm.$d(_vm.payload.eta * 1e3, 'short'))}})]):_vm._e(),(_vm.payload.state === 'Queued')?_c('UiButton',{staticClass:"d-block width-full button--submit",on:{"click":_vm.execute}},[_vm._v(" "+_vm._s(_vm.$t('page.execute'))+" ")]):_vm._e(),_c('UiButton',{staticClass:"width-full mt-2",on:{"click":_vm.downloadReport}},[_vm._v(" "+_vm._s(_vm.$t('page.downloadReport'))+" ")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }